@import '../../style/variables.scss';

.StopDialog {
  height: 100%;
  width: 100%;
  border-color: red;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .title {
    font-size: 30px;
    line-height: 36px;
  }
  .paragraph {
    font-size: 20px;
    line-height: 24px;
  }
  .buttonRow {
    padding: 0 65px;
    display: flex;
    justify-content: space-between;
    .stopButton {
      @include componentContainer;
      padding: 20px;
      background: red;
    }
    .dontStopButton {
      @include componentContainer;
      padding: 20px;
      background: #7E7E7E;
    }
  }

  .red {
    color: red
  }
}