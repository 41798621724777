@import '../../style/variables.scss';


.Timers {
  @include componentContainer;
  grid-column: 3/4;
  background-color: red;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .timer {
    display: flex;
    height: 30%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    &-val {
      font-size: 1.5em;
      font-weight: 400;
    }
  }
  .yellow {
    color: yellow
  }
  .offline {
    color: #4C4C4C;
  }
}