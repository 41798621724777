$headerHeight: 4.5%;
$topSpacerHeight: 2.4%;
$bottomSpacerHeight: 10.6%;

$confirmationGreen: #18FF00;
@mixin lesserComponentContainer {
  background: linear-gradient(0deg, #2E3736, #2E3736);
  border-radius: 2px;
  box-shadow:  
    0px 8px 8px rgba(0, 0, 0, 0.24), 
    0px 0px 8px rgba(0, 0, 0, 0.12), 
    0px 2px 2px rgba(0, 0, 0, 0.24), 
    0px 0px 2px rgba(0, 0, 0, 0.12);
}
@mixin componentContainer {
  @include lesserComponentContainer;
  &:hover {
    box-shadow: 
      -4px 8px 8px rgba(0, 0, 0, 0.36),   
      0px 0px 8px rgba(0, 0, 0, 0.24), 
      0px 2px 2px rgba(0, 0, 0, 0.48), 
      0px 0px 2px rgba(0, 0, 0, 0.75);
  }
  &:active {
    box-shadow: 
      0px 0px 8px rgba(0, 0, 0, 0.12), 
      0px 2px 2px rgba(0, 0, 0, 0.24), 
      0px 0px 2px rgba(0, 0, 0, 0.12);
  }
}
