@import '../../style/variables.scss';

.LogsDialog {
  &-Top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    &-Header {
      font-size: 2em;
    }
  }
  .errorLine{
    margin: 10px 0px;
    .death {
      color: #FF0000;
    }
    .warn {
      color: #FF7900
    }
  }
}
div::-webkit-scrollbar {
  width: 4px;
}
div::-webkit-scrollbar-thumb {
  background: #FFFFFF;
  border-radius: 2px;
}