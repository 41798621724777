@import '../../style/variables.scss';

.Dialog {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-Header {
    font-size: 2em;
  }
  &-Row {
    display: grid;
    grid-template-columns: 40% 60%;
    font-size: 1.3em;
    align-items: flex-end;
  }
  .divDivider {
    margin-bottom: 0;
  }
  .missing {
    color: #FFFF00;
  }
}