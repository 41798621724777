@import '../../style/variables.scss';
.Camera {
  &-Active { 
    @include componentContainer;
  }
  &-Inactive {
    @include lesserComponentContainer;
  }
  width: 38%;
  display: flex;
  align-items: center;
  justify-content: center;
  .Image {
    height: 100%;
  }
}