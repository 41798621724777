.x-major-grid .tick {
  stroke: lightgrey;
  opacity: 0.25;
  stroke-dasharray: 3 3;
}
.x-minor-grid .tick {
  stroke: lightgrey;
  opacity: 0.15;
  stroke-dasharray: 1 2;
}
.y-major-grid .tick {
  stroke: lightgrey;
  opacity: 0.25;
  stroke-dasharray: 3 3;
}
.y-minor-grid .tick {
  stroke: lightgrey;
  opacity: 0.15;
  stroke-dasharray: 1 2;
}
.grid path {
  stroke-width: 0;
}