@import '../../style/variables.scss';

.LeftSummary{
  @include componentContainer;
  height: calc(100% - 50px);
  grid-row: 3/4;
  grid-column: 2/3;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &-Row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .missing {
    color: #FFFF00;
  }
  .offline {
    color: #4C4C4C;
  }
}