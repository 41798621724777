@import '../../style/variables.scss';

.Loading {
  display: flex;
	justify-content: space-between;
  align-items: center;
  width: 30%;

  &-Logo {
    max-width: 50%;
    animation: App-logo-spin infinite 20s linear;
  }

  &-Balls  {
    $anim-drt: 0.4s;
    $anim-ease: cubic-bezier(.6,.05,.15,.95);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: white;

    &:nth-child(1) {
      animation: bouncing $anim-drt alternate infinite $anim-ease;
    }

    &:nth-child(2) {
      animation: bouncing $anim-drt $anim-drt/4 alternate infinite $anim-ease backwards;
    }

    &:nth-child(3) {
      animation: bouncing $anim-drt $anim-drt/2 alternate infinite $anim-ease backwards;
    }
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes bouncing {
	0% {
		transform: translate3d(0, 10px, 0) scale(1.2, 0.85);
	}
	100% {
		transform: translate3d(0, -20px, 0) scale(0.9, 1.1);
	}
}


.Atlas{
  color: white;
  display: grid;
  grid-template-columns: 30% 70%;
  height: calc(100% - #{$headerHeight + $topSpacerHeight + $bottomSpacerHeight});
  &-leftContainer {
    grid-column: 1/2;
    display: grid;
    grid-template-columns: 30% 70%;
    grid-template-rows: 77.6% 5.6% 16.8%;
    margin-right: 4.7%;
    min-height: 100%;
  }
  @media only screen and (max-width: 600px) {
    &-leftContainer {
      grid-template-columns: 80% 20%;
    }
  }
  &-rightContainer {
    grid-column: 2/3;
    display: grid;
    // grid-template-columns: 20% 35% 45%;
    grid-template-rows: 19% 36.4% 5.6% 39%;
    margin-left: 1.8%;
    margin-right: 9%;
    min-height: 100%;

    .tempsTimersContainer {
      grid-row: 2/3;
      display: grid;
      grid-template-columns: 83% 4.5% 12.5%;
    }
    &-bottom {
      display: flex;
      justify-content: space-between;
    }
  }
  &-version {
    display: flex;
    justify-content: flex-end;
    padding-top: 25px;
    font-size: 15px;
    font-style: italic;
  }
}

.noData { 
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 3em;
  text-align: center;
  flex-direction: column;
}