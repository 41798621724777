.Graph{
  height: 100%;
  width: 100%;
}

.graph-title {
  text-anchor: middle;
  stroke: none;
  fill: white;
  font-size: 1.3em;
}

.global {
  fill: white;
  font-size: 0.9em;
}