.RightSummary {
  grid-row: 1/2;
  display: flex;
  justify-content: space-between;
  .leftContainer {
    .machineName {
      font-size: 25px;
    }
    .offline {
      color: #4c4c4c;
    }
    .orderName {
      height: 40px;
      font-size: 35px;
    }
    .status {
      font-size: 25px;
      &-Heating {
        color: #ff5a00;
      }
      &-Cooling {
        color: #86d6d8;
      }
      &-Printing {
        color: #18ff00;
      }
      &-Offline {
        color: #4c4c4c;
      }
    }
  }
  .rightContainer {
    display: flex;

    .stopContainer {
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: auto;
      align-items: center;
      justify-content: center;
      img {
        grid-row: 1/2;
        grid-column: 1/2;
      }
      img:hover {
        filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 1));
        cursor: pointer;
      }
    }
  }
}