@import '../../style/variables.scss';
.machineSelectionContainer {
  grid-row: 1/4;
  grid-column: 1/2;
  height: 100%;
  max-height: 100%;
  display: grid;
  grid-template-columns: 30% 40% 30%;
  grid-template-rows: 100%;

  .machineSelection {
    @include lesserComponentContainer;
    height: calc(100% - 26px);
    max-height: calc(100% - 26px);
    grid-row: 1/2;
    grid-column: 2/3;
    padding: 13px 15%;
    background-color: blue;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  
    .imageContainer {
      margin: 20% 0;
      display: grid;
      justify-items: center;
      align-items: center;
      border: 2px solid transparent;
      border-radius: 3px;
      &:hover {
        border: 3px solid #00FFF0;
        border-radius: 8px;
      }
      // &-true { //not sure what this is for
        // margin-bottom: -25px !important
      // }
      .currentMachine {
        border: 4px solid #00FFF0;
        border-radius: 11px;
        opacity: .5;
        grid-column: 1/2;
        grid-row: 1/2;
        width: 110%;
        height: 110%;
      }
      .image {
        grid-column: 1/2;
        grid-row: 1/2;
        width: 100%;
        height: auto;
      }
      .stateImage {
        grid-column: 1/2;
        grid-row: 1/2;
        width: 100%;
        height: auto;
      }
      .historyDot {
        grid-column: 1/2;
        grid-row: 1/2;
        width: 100%;
        height: auto;
      }
    }
    .imageContainer:first-child {
      margin-top: 0;
    }
    .tooltip {
      display: none;
      height: 25px;
      position: absolute;
  
      &.visible {
        display: flex;
      }
      .pointer {
        margin-right: -1px;
        width: 12px;
        min-width: 12px;
      }
      .textContainer {
        border: 1px solid #00FFF0;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0px 8px;
        display: flex;
        padding-top: 2px;
      }
    }
  }
  
}
