@import '../../style/variables.scss';

.Login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  height: 100vh;

  &-Container {
    height: 74%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &-Name {
    font-family: Myriad Pro;
    font-size: 50px;
    font-weight: bold;
    letter-spacing: .2em;
  }

  &-Logo {
    width: 25vw;
  }

  &-Message {
    height:24px;
    font-family: Myriad Pro;
    font-weight: bolder;
    font-size: 20px;
    line-height: 24px;
    color: rgba(255, 0, 0, 0.5);
  }

  &-Form {
    width: 31.25vw;
    
    .InputContainer {
      position: relative;
      width: 100%;
      margin-bottom: 1em;

      &:before {
        left: 0;
        right: 0;
        bottom: 0;
        content: "\00a0";
        position: absolute;
        transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border-bottom: 1px solid black;
        pointer-events: none;
      }
      &:hover::before {
        border-bottom: 2px solid black
      }

      &:after {
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        position: absolute;
        transform: scaleX(0);
        transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
        border-bottom: 2px solid white;
        pointer-events: none;
      }
      &:focus-within::after {
        transform: scaleX(1) !important;
      }

      .Input {
        color: white;
        background: transparent;
        border: none;
        width: 100%;
        padding: 8px 0;
        text-align: center;
        font-family: Myriad Pro;
        font-size: 20px;
        font-weight: bold;
        &:focus {
          outline: none;
        }
      }
    }

    .Button {
      @include componentContainer;
      width: 100%;
      padding: 10px 0;    
      color: white;
      border: none;
      border-radius: 5px;
      background-color: #00A5CF;
      margin-top: 1em;
    }
  }
}
