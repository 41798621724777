.filterButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  grid-row: 1/2;
  grid-column: 1/2;
  padding: 10px 10px 0 0;

  &.show {
    visibility: visible;
  }

  &.hidden{
    visibility: hidden;
  }

  .buttonRowContainer {
    display:flex;
    flex-direction: row;
    
    .cyanDot {
      justify-content:left;
      font-size: 10px;
      color: #00FFF0;
      padding-right:3px;
      padding-top:2px;

      &.show {
        visibility: visible;
      }

      &.hidden{
        visibility: hidden;
      }
    }
    
    .filterButtons {
      height: 15px;
      width: 15px; 
      padding-bottom: 5px;
    }
  }
}
