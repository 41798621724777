@import './style/variables.scss';

.App {
  height: 100vh;
  background-color: #2E3736;
  display: flex;
  flex-direction: column;
}
.topSpacer {
  height: $topSpacerHeight;
}
.bottomSpacer {
  height: $bottomSpacerHeight;
}

.divDivider {
  background-color: white;
  height: 2px;
  margin-bottom: 7px;
}

.offline-true {
  background-color: #4C4C4C;
}

