.modal {
  &-open {
    &-false {
      display: none; /* Hidden by default */
    }
    &-true {
      display: flex
    }
  }

  position: fixed;
  align-items: center;
  justify-content: center;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */

  &-content {
    background: linear-gradient(0deg, #2E3736, #2E3736), #009688;
    z-index: 3;
    border: 2px solid #FFFFFF;
    border-radius: 3px;
    box-sizing: border-box;
    box-shadow: -4px 8px 8px rgba(0, 0, 0, 0.6), 
      0px 0px 8px rgba(0, 0, 0, 0.24), 
      0px 2px 2px rgba(0, 0, 0, 0.48), 
      0px 0px 10px rgba(0, 0, 0, 0.75);
    color: white;
    padding: 15px;
    overflow: scroll;
    overflow-x: auto;
  }
  
  .borderColorRed {
    border-color: red;
  }
  .size {
    &-md {
      height: 39.2vh;
      width: 35.6vw;
    }
    &-tlsk {
      width: 36vw;
      height: 83vh;
    }
    &-lg {
      width: 75vw;
      height: 40vh;
    }
  }
}

.back-drop {
  z-index: 2;
  background-color: rgba(48, 49, 48, 0.42);
  height: 100%;
  position: fixed;
  transition: all 1.3s;
  width: 100%;
}