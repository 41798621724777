@import '../../style/variables.scss';

.Header {
  &-true {
    width: calc(100% - 2px);
    background-color: #2e3736;
    display: flex;
    align-items: center;
    padding-left: 2px;
    height: $headerHeight;
  }
  &-false {
    visibility: hidden;
    height: $headerHeight;
  }


  a {
    height: 100%; 
  }
  &-Logo {
    height: 100%;
  }

  &-Button {
    // margin: 10px;
    padding: 16px 18px;
    background: transparent;
    border: none;
    font-size: 1.5em;
    color: white;
    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }
} 