.bottomInfo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 25px;

  .headerToggle {
    &-hidden { 
      opacity: .5;
    }
  }

  img {
    margin: 0 10px;
    height: 15px;
    width: 15px;
    // TODO: fix this by properly placing this component element
    // currently it's sitting ON TOP of the bottom spacer
    // band aid fix so imgs are clickable above the spacer
    z-index: 50; 
  }

  .version {
    font-size: 15px;
    font-style: italic;
  }

  .debugToggle {
    opacity: .5;
    &-hidden { 
      opacity: 1;
    }
  }
}