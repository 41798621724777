.percentageArc {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto;
  align-items: center;
  margin-right: 55px;
  font-size: 24px;

  .svg {
    grid-row: 1/2;
    grid-column: 1/2;
  }

  .percentage {
    grid-row: 1/2;
    grid-column: 1/2;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
  .offline {
    color: #4C4C4C;
  }

} 
