@import '../../style/variables.scss';

.Temperatures{
  grid-column: 1/2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .Active {
    @include componentContainer;
  }
  .Inactive {
    @include lesserComponentContainer;
    color: grey;
  }
  &-TempContainer {
    height: 23%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2.0em;
    padding: 0px 25px;
    .label{
      width: 40%;
    }
    .data {
      width: 60%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      color: $confirmationGreen;
      .smallerTemp {
        font-size: .5em;
      }
      .smallerTemp.status-ok {
        opacity: 0.5;
      }
    }
    .status {
      &-ok {
        color: #18FF00
      }
      &-warn {
        color: #FF7900;
      }
      &-death {
        color: #FF0000;
      }
      &-undefined {
        color: #FFFF00;
      }
    }
    .ignoreAmbient {
      color: #4C4C4C;
    }
    .offline {
      color: #4C4C4C;
    }
    .arrow {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}