@import '../../style/variables.scss';
.Logs{
  width: 54%;
  .offline {
    color: #4c4c4c;
  }
  .ErrorLogs {
    @include componentContainer;
    height: calc(100% - 50px);
    padding: 25px;
    width: 100%;
    display: grid;
    grid-template-rows: 15% auto 1fr;
    &-Top {
      height: 15%;
      display: flex;
      justify-content: space-between;
      &-Header {
        font-size: 2em;
      }
    }
    .errorsList {
      display: flex;
      flex-direction: column;
      .errorBrief {
        margin: 8px 0;
        .value {
          font-weight: 900;
        }
        .death {
          color: #FF0000;
        }
        .warn {
          color: #FF7900
        }
      }
    }
  }
}