@import '../../style/variables.scss';

.MachineVisual{
  @include componentContainer;
  height: 100%;
  grid-row: 1/2;
  grid-column: 2/3;
  display: grid;
  grid-template-columns: 20% 60% 20%;
  grid-template-rows: 15% 70% 15%;
  &-Visual {
    height: 100%;
    width: 100%;
    grid-row: 2/3;
    grid-column: 2/3;
  }
  .onTop {
    z-index: 1;
  }
}